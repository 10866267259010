<template>
	<div class="w-full border shadow-md rounded-xl">
		<div @click="openModal()">
			<WwImage
				:src="photo.filename"
				classes="rounded-xl"
				class="block border rounded-xl"
				:alt="photo.description"
				:fallback-image="DEFAULT_WW_GRAY_LOGO"
				:width="250"
				:height="250"
				:image-path="USER_IMAGES"
			/>
		</div>
		<div
			v-if="showPhotoEditControls"
			class="flex items-center justify-between pt-2 mt-2"
		>
			<div class="flex mr-4">
				<img
					class="w-4 h-4 mr-2"
					src="@/assets/icons/close2.svg"
					alt="Delete"
					@click.stop="deleteImage()"
				>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { USER_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		photo: {
			type: Object,
			required: true
		},
		photoId: {
			type: Number,
			required: true
		}
	},
	emits: [ 'emit-open-modal' ],
	data() {
		return {
			tempCaption: '',
			DEFAULT_WW_GRAY_LOGO,
			caption: '',
			USER_IMAGES
		}
	},
	computed: {
		...mapGetters('user', [
			'userId'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		showPhotoEditControls() {
			return this.auth.id === this.userId
		},
		deleteData() {
			return {
				user_id: this.userId,
				image_id: parseInt(this.photo.fileid)
			}
		}
	},
	methods: {
		...mapActions('user', [
			'removePhoto'
		]),
		...mapMutations('toast', [ 'showToast' ]),
		openModal() {
			this.$emit('emit-open-modal', this.photoId) // emits to views\user\Photos.vue
		},
		async deleteImage() {
			try {
				await this.removePhoto(this.deleteData)
			} catch (e) {
				logError(e)
			}
		}
	}
}
</script>
